import React from "react"
import Navbar from "../components/navbar"
import Sectionone from "../components/productone/secone"
import Sectiontwo from "../components/productone/sectwo"
import Sectionthree from "../components/productone/secthree"
import Footer from "../components/footer"

export default function serviceone() {
  return (
    <div>
      <Navbar />
      <Sectionone />
      {/* <Sectiontwo/>
        <Sectionthree/> */}
      <Footer />
    </div>
  )
}
